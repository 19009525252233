@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&amp;subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese);
body {
  background-color: #000;
  margin: 0;
  color: #ffffff;
  margin-bottom: 15px;
  padding-bottom: 75px;
  min-width: 100%;
}

.app {
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
}

header {
  font-family: "Open Sans", "Helvetica", sans-serif;
  color: #fff;
  top: 0;
  height: 50px;
  font-size: 3em;
  display: flex;
  justify-content: space-between;
}

header img {
  width: 2em;
}

.description {
  margin-bottom: auto;
  font-size: 18px;
  max-width: 500px;
}

a {
  text-decoration: none;
  color: #f4f4f4;
  padding-left: 22px;
  margin: 0;
}

h2 {
  color: #fff;
  font-size: 3.8em;
  letter-spacing: 7px;
}

h3 {
  color: #fff;
}

.Reminder_Sec {
  bottom: 25px;
  padding: 45px;
  text-align: right;
  right: 0;
  display: flex;
  margin-left: auto;
  margin-top: auto;
}

.Reminder_Sec .image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.Reminder_Sec .image-container h3 {
  margin-bottom: 20px;
  font-size: 30px;
}

.Reminder_Sec .image-container img {
  width: 40vw;
  margin-right: 50px;
}

.form-container  {
  display: flex;
  flex-direction: column;

}

.Line2 {
  margin-top: auto;
  padding-top: 30px;
  line-height: 45px;
}

input {
  background-color: transparent;
  border-radius: 2px;
  border: none;
  border-bottom: 1px solid #fff;
  width: 75%;
  height: 30px;
  color: #fff;
  padding: 2px 16px;
  outline: none;
}

input[value] {
  color: #dcdc;
}

input[type="email"]:focus {
  border: none;
  border-bottom: 1.5px solid #fff;
}

.button {
  border: 0.5px solid #fff;
  outline: none;
  background-color: transparent;
  color: #fff;
  height: 35px;
  cursor: pointer;
}

.button:hover {
  color: #000;
  background-color: #fff;
  border: 0.5px solid #000;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

